import router from "@/router";
import store from "@/store";

// 白名单
const whiteList = ["/login"];
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  if (store.getters.token) {
    if (to.path === "/login") {
      next("/");
    } else {
      if (!store.getters.hasUserInfo) {
        // 用户信息不存在
        const { permission } = await store.dispatch("user/getUserInfo");
        console.log(permission);
        // 生成新的路由
        const filterRoutes = await store.dispatch("permission/filterRoutes", [
          "articleRanking",
          "articleCreate",
          "articleTurntable",
          "voucherGrant",
          "voucherConfig"
        ]);
        // 循环添加对应动态路由
        filterRoutes.forEach((item) => {
          router.addRoute(item);
        });
        // 添加完动态路由之后，需要在进行一次主动跳转
        return next(to.path);
      }
      next();
    }
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next();
    } else {
      next("/login");
    }
  }
});
