import layout from "@/layout";

export default {
  path: "/activity",
  component: layout,
  redirect: "/activity/voucherConfig",
  name: "voucherConfig",
  meta: {
    title: "活动管理",
    icon: "activity"
  },
  children: [
    {
      path: "/activity/voucherConfig",
      name: "voucherConfig",
      component: () => import(/* webpackChunkName: "voucherConfig" */ "@/views/activity/voucher/config"),
      meta: {
        title: "代金劵配置",
        icon: "voucher-config"
      }
    }
  ]
};
