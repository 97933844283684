import request from "@/utils/request";

export const UPLOAD_IMG_URL = "public/upload/file"; // upload img

/* 游戏列表 */
export const gameList = () => {
  return request({
    url: "public/getProductList"
  });
};

/* 代金劵 */
export const voucherList = (params) => {
  return request({
    url: "public/getVoucherList",
    params
  });
};
