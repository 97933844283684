import layout from "@/layout";

export default {
  path: "/activity",
  component: layout,
  redirect: "/activity/turntable",
  name: "articleTurntable",
  meta: {
    title: "活动管理",
    icon: "activity"
  },
  children: [
    {
      path: "/activity/turntable",
      name: "articleTurntable",
      component: () => import(/* webpackChunkName: "articleTurntable" */ "@/views/activity/turntable/index.vue"),
      meta: {
        title: "大转盘管理",
        icon: "article-turntable"
      }
    },
    {
      path: "/activity/lotteryRecord/:id",
      name: "lotteryRecord",
      props: true,
      component: () => import(/* webpackChunkName: "lotteryRecord" */ "@/views/activity/turntable/lotteryRecord.vue"),
      meta: {
        title: "抽奖记录"
      }
    }
  ]
};
