import { createRouter, createWebHashHistory } from "vue-router";
import { publicRoutes } from "@/router/publicRoutes";
import store from "@/store";
import UserManageRouter from "./modules/UserManage";
import RoleListRouter from "./modules/RoleList";
import PermissionListRouter from "./modules/PermissionList";
import Turntable from "./modules/Turntable";
import VoucherGrant from "./modules/VoucherGrant";
import VoucherConfig from "./modules/VoucherConfig";
import DataSummarize from "./modules/DataSummarize";
/**
 * 私有路由表
 */
export const privateRoutes = [
  UserManageRouter,
  RoleListRouter,
  PermissionListRouter,
  Turntable,
  VoucherConfig,
  VoucherGrant,
  DataSummarize
];

/**
 * 初始化路由表 验证
 */
export function resetRouter() {
  if (store.getters.userInfo && store.getters.userInfo.permission && store.getters.userInfo.permission.menus) {
    const menus = store.getters.userInfo.permission.menus;
    menus.forEach((menu) => {
      router.removeRoute(menu);
    });
  }
}

//  process.env.NODE_ENV === "production" ? createWebHistory() : createWebHashHistory()
const router = createRouter({
  history: createWebHashHistory(),
  routes: publicRoutes
});

export default router;
