import { getItem, setItem } from "@/utils/storage";
import { GAME_LIST } from "@/constant";
import { gameList } from "@/api/public";

export default {
  namespaced: true,
  state: () => ({
    gameList: getItem(GAME_LIST) || []
  }),
  mutations: {
    setGameList(state, data) {
      state.gameList = data;
      setItem(GAME_LIST, data);
    }
  },
  actions: {
    async getGameList() {
      const res = await gameList();
      this.commit("public/setGameList", res);
      return res;
    }
  }
};
