<template>
  <!--  v-bind='$attrs'父类传入的自定义属性  -->
  <el-dropdown v-bind="$attrs" trigger="click" class="theme" @command="handleSetTheme">
    <!-- 图标 -->
    <div>
      <el-tooltip content="主题更换">
        <div><svg-icon icon="change-theme" id="guide-theme" /></div>
      </el-tooltip>
    </div>
    <!-- menu -->
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="color">主题更换</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <!-- 弹出层 -->
  <div>
    <SelectColor v-model="selectColorVisible" />
  </div>
</template>

<script setup>
/* 换肤 */
import SelectColor from "./components/SelectColor";
import { ref } from "vue";
const selectColorVisible = ref(false);
const handleSetTheme = () => {
  selectColorVisible.value = true;
};
</script>

<style lang="scss" scoped></style>
