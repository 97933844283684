// import md5 from "md5";
import { login } from "@/api/sys";
import { getItem, removeAllItem, setItem } from "@/utils/storage";
import { TOKEN } from "@/constant";
import router from "@/router";
import { setTimeStamp } from "@/utils/auth";

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || "",
    userInfo: {}
  }),
  mutations: {
    setToken(state, token) {
      state.token = token;
      setItem(TOKEN, token);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    }
  },
  actions: {
    /* 登录 */
    login(context, userInfo) {
      const { username, password } = userInfo;
      return new Promise((resolve, reject) => {
        login({ username, password: password })
          .then((res) => {
            this.commit("user/setToken", res.token);
            this.commit("user/setUserInfo", res);
            setTimeStamp();
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /* 获取用户信息 */
    async getUserInfo() {
      this.commit("user/setUserInfo", { name: "11" });
      // const res = await getUserInfo();
      return "11";
    },
    /* 退出登录 */
    logout() {
      // 重置路由
      // resetRouter();
      this.commit("user/setToken", "");
      this.commit("user/setUserInfo", {});
      removeAllItem();
      router.replace("/login");
    }
  }
};
