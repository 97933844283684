<template>
  <div class="navbar d-fx">
    <div class="one-f">
      <!-- 收缩菜单     -->
      <Hamburger class="hamburger-container" />
      <!-- 面包屑 -->
      <Breadcrumb id="guide-breadcrumb" class="breadcrumb-container" />
    </div>
    <div class="right-menu">
      <div class="right-con">
        <guide class="right-menu-item hover-effect" />
        <!-- 搜索 -->
        <header-search class="right-menu-item hover-effect" />
        <!-- 全屏 -->
        <Screenfull class="right-menu-item hover-effect" />
        <!-- 切换主题颜色 -->
        <theme-picker class="right-menu-item hover-effect" />
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ $store.getters.userName }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="user-dropdown">
              <router-link to="/">
                <el-dropdown-item class="user-dropdown">首页</el-dropdown-item>
              </router-link>
              <el-dropdown-item class="user-dropdown" @click="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import {} from "vue";
import { useStore } from "vuex";
import Hamburger from "@/components/Hamburger";
import Breadcrumb from "@/components/Breadcrumb";
import ThemePicker from "@/components/ThemePicker";
import Screenfull from "@/components/Screenfull";
import HeaderSearch from "@/components/HeaderSearch";
import Guide from "@/components/Guide";
import { ArrowDown } from "@element-plus/icons-vue";
const store = useStore();
const logout = () => {
  store.dispatch("user/logout");
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-con {
    display: flex;
    align-items: center;
    padding-right: 16px;

    ::v-deep .right-menu-item {
      padding: 0 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;
      button {
        border: none;
      }
      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    ::v-deep .avatar-container {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
    .right-menu-item.hover-effect:hover {
      background: none;
    }
    .el-dropdown-link {
      display: flex;
      align-items: center;
    }
  }
}
</style>
