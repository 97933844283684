<template>
  <div>
    <div class="header-search d-fx" :class="{ show: isShow }">
      <el-tooltip class="box-item" effect="dark" content="搜索" placement="bottom">
        <div class="search-svg">
          <svg-icon id="guide-search" class-name="search-icon" icon="search" @click.stop="onShowClick" />
        </div>
      </el-tooltip>
      <el-select
        ref="headerSearchSelectRef"
        class="header-search-select"
        v-model="search"
        filterable
        default-first-option
        remote
        placeholder="Search"
        :remote-method="querySearch"
        @change="onSelectChange"
      >
        <el-option
          v-for="option in searchOptions"
          :key="option.item.path"
          :label="option.item.title.join(' > ')"
          :value="option.item"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { filterRoutes } from "@/utils/route";
import Fuse from "fuse.js";
import { generateRoutes } from "./FuseData";
const router = useRouter();

// 数据源
let searchPool = computed(() => {
  const routes = filterRoutes(router.getRoutes());
  return generateRoutes(routes);
});

const fuse = new Fuse(searchPool.value, {
  // 是否按优先级进行排序
  shouldSort: true,
  // 匹配算法放弃的时机， 阈值 0.0 需要完美匹配（字母和位置），阈值 1.0 将匹配任何内容。
  threshold: 0.4,
  // 匹配长度超过这个值的才会被认为是匹配的
  minMatchCharLength: 1,
  // 将被搜索的键列表。 这支持嵌套路径、加权搜索、在字符串和对象数组中搜索。
  // name：搜索的键
  // weight：对应的权重
  keys: [
    {
      name: "title",
      weight: 0.7
    },
    {
      name: "path",
      weight: 0.3
    }
  ]
});

// 控制search 显示
const isShow = ref(false);
// el-select 实例
const headerSearchSelectRef = ref(null);
const onShowClick = () => {
  isShow.value = !isShow.value;
  headerSearchSelectRef.value.focus();
};

// search 相关
const search = ref("");
// 搜索结果
const searchOptions = ref([]);

// 搜索方法
const querySearch = (query) => {
  if (query !== "") {
    searchOptions.value = fuse.search(query);
  } else {
    searchOptions.value = [];
  }
};

// 选中回调
const onSelectChange = (val) => {
  router.push(val.path);
  onClose();
};

/**
 * 关闭 search 的处理事件
 */
const onClose = () => {
  headerSearchSelectRef.value.blur();
  isShow.value = false;
  searchOptions.value = [];
};
/**
 * 监听 search 打开，处理 close 事件
 */
watch(isShow, (val) => {
  if (val) {
    document.body.addEventListener("click", onClose);
  } else {
    document.body.removeEventListener("click", onClose);
  }
});
</script>

<style lang="scss" scoped>
.header-search {
  height: 50px;
  align-items: center;
  font-size: 0 !important;
  .search-icon {
    cursor: pointer;
    font-size: 19px;
    vertical-align: middle;
  }
  .header-search-select {
    font-size: 18px;
    transition: width 0.2s;
    width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;

    ::v-deep .el-input__inner {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none !important;
      border-bottom: 1px solid #d9d9d9;
      vertical-align: middle;
    }
  }
  &.show {
    .header-search-select {
      width: 210px;
      margin-left: 10px;
    }
  }
}
</style>
