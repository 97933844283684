import { createStore } from "vuex";
import user from "@/store/modules/user";
import getters from "@/store/getters";
import app from "@/store/modules/app";
import theme from "@/store/modules/theme";
import permission from "./modules/permission";
import publicData from "./modules/public";
import turntable from "./modules/turntable";

export default createStore({
  getters,
  modules: {
    user,
    app,
    theme,
    permission,
    public: publicData,
    turntable
  }
});
