// token
export const TOKEN = "token";
// token 时间戳
export const TIME_STAMP = "timeStamp";
// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000;

// 主题色保存的 key
export const MAIN_COLOR = "mainColor";
// 默认色值
export const DEFAULT_COLOR = "#304156";
// tags
export const TAGS_VIEW = "tagsView";

export const GAME_LIST = "gameList";

// 成功code
export const SUCCESS_CODE = 0;

/* token 过期 */
export const TOKEN_OVERDUE_CODE = 1001;
