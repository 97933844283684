import layout from "@/layout";

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login/index")
  },
  {
    path: "/",
    component: layout,
    redirect: "/profile",
    children: [
      {
        path: "/profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "profile" */ "@/views/profile/index"),
        meta: {
          title: "个人中心",
          icon: "el-icon-user"
        }
      },
      {
        path: "/404",
        name: "404",
        component: () => import(/* webpackChunkName: "error-page" */ "@/views/error-page/404")
      },
      {
        path: "/401",
        name: "401",
        component: () => import(/* webpackChunkName: "error-page" */ "@/views/error-page/401")
      }
    ]
  }
];
