import layout from "@/layout";

export default {
  path: "/dataReport",
  component: layout,
  redirect: "/dataReport/dataSummarize",
  name: "dataSummarize",
  meta: {
    title: "数据报表",
    icon: "activity"
  },
  children: [
    {
      path: "/dataReport/dataSummarize",
      name: "dataSummarize",
      component: () => import(/* webpackChunkName: "dataSummarize" */ "@/views/dataReport/summarize/index"),
      meta: {
        title: "数据概述",
        icon: "voucher-grant"
      }
    }
  ]
};
