/* 转盘编辑 */

export const initData = {
  id: "",
  title: "",
  status: "1",
  type: "1",
  productId: [],
  tips: "",
  itemsImg: "",
  buttonImg: "",
  mainKey: "",
  cost: "",
  realityCost: "",
  showWhere: [
    {
      key: 1,
      num: "",
      money: ""
    }
  ],
  awardList: [
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    }
  ],
  awardList1: [
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    }
  ],
  awardList2: [
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    },
    {
      key: 1,
      name: "",
      type: "1",
      rate: "",
      reward: [{ key: 11, limItnum: "", name: "" }]
    }
  ]
};

export default {
  namespaced: true,
  state: () => ({
    turntableData: { ...initData }
  }),
  mutations: {
    setTurntableData(state, newData) {
      state.turntableData = newData;
    },
    restoreTurntableData(state) {
      state.turntableData = initData;
    }
  },
  actions: {}
};
