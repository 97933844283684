<template>
  <div class="app-main">
    <el-scrollbar>
      <router-view v-slot="{ Component, route }">
        <keep-alive :include="$store.getters.tagsViewList.map((item) => item.name)">
          <component :is="Component" :key="route.name" />
        </keep-alive>
      </router-view>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { watch } from "vue";
import { isTags } from "@/utils/tags";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const route = useRoute();
const store = useStore();

/**
 * 生成 title
 */
const getTitle = (route) => {
  let title = "";
  if (!route.meta) {
    // 处理无 meta 的路由
    const pathArr = route.path.split("/");
    title = pathArr[pathArr.length - 1];
  } else {
    title = route.meta.title;
  }
  return title;
};

/**
 * 监听路由变化
 */
watch(
  route,
  (to) => {
    if (!isTags(to.path)) return;
    const { fullPath, meta, name, params, path, query } = to;
    store.commit("app/addTagsViewList", {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to)
    });
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" scoped>
.app-main {
  height: calc(100vh);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 104px 20px 20px 20px;
  box-sizing: border-box;
  background-color: #f0f0f0;
}
</style>
