import layout from "@/layout";

export default {
  path: "/activity",
  component: layout,
  redirect: "/activity/voucherGrant",
  name: "voucherGrant",
  meta: {
    title: "活动管理",
    icon: "activity"
  },
  children: [
    {
      path: "/activity/voucherGrant",
      name: "voucherGrant",
      component: () => import(/* webpackChunkName: "voucherGrant" */ "@/views/activity/voucher/grant"),
      meta: {
        title: "代金劵发放",
        icon: "voucher-grant"
      }
    }
  ]
};
