import request from "@/utils/request";
/*
 * 登录
 * */
export const login = (data) => {
  return request({
    url: "/login",
    method: "post",
    data
  });
};

/*
 * 获取用户信息
 * */
export const getUserInfo = () => {
  return request({
    url: "/sys/profile"
  });
};

/* 获取用户列表 */
export const getUserList = (params) => {
  return request({
    url: "/user/list",
    params
  });
};
