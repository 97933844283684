import axios from "axios";
import store from "@/store";
import { ElMessage } from "element-plus";
import { isCheckTimeout } from "@/utils/auth";
import { SUCCESS_CODE, TOKEN_OVERDUE_CODE } from "@/constant";
import { domainName } from "@/config";

const service = axios.create({
  baseURL: `${domainName}`,
  timeout: 5000
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 存在token
    if (store.getters.token) {
      // 前端计算登录过期
      if (isCheckTimeout()) {
        store.dispatch("user/logout");
        return Promise.reject(new Error("token 失效"));
      }
      config.headers.Authorization = `Bearer ${store.getters.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const { code, message, data } = response.data;
    if (code === SUCCESS_CODE) {
      return data;
    } else {
      if (code === TOKEN_OVERDUE_CODE) {
        ElMessage.error(message);
        store.dispatch("user/logout");
      } else {
        ElMessage.error(message);
        return Promise.reject(new Error(message));
      }
    }
  },
  (error) => {
    // 处理 token 超时问题
    if (error.response && error.response.data && error.response.data.code === 401) {
      store.dispatch("user/logout");
    }
    ElMessage.error(error.message);
    return Promise.reject(error);
  }
);

export default service;
