import dayjs from "dayjs";
import rt from "dayjs/plugin/relativeTime";
// 语言包
import "dayjs/locale/zh-cn";

const dateFilter = (val, format = "YYYY-MM-DD HH:mm:ss") => {
  if (!isNaN(val)) {
    val = parseInt(val);
  }
  return dayjs(val).format(format);
};

// 加载相对时间插件
dayjs.extend(rt);
function relativeTime(val) {
  if (!isNaN(val)) {
    val = parseInt(val);
  }
  return dayjs().locale("zh-cn").to(dayjs(val));
}

export default (app) => {
  app.config.globalProperties.$filters = {
    dateFilter,
    relativeTime
  };
};
